<script lang="ts" setup>
const props = defineProps({
  topClasses: {
    type: String,
    default: 'py-[1.88rem] lg:py-[3.12rem]'
  },
  bottomClasses: {
    type: String,
    default: 'bg-gray-10 py-[1.25rem] lg:py-[3.12rem]'
  }
})
</script>
<template>
  <div v-if="$slots.top" :class="props.topClasses">
    <div class="container">
      <slot name="top" />
    </div>
  </div>
  <div :class="props.bottomClasses">
    <div class="main-panel container">
      <slot name="bottom" />
    </div>
  </div>
</template>
