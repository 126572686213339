<script setup lang="ts">
const { data: user } = useAuth()
const { t } = useI18n()
const { listJobsReactive } = useJob()
const toLocalePath = useLocalePath()

const props = defineProps({
  // Search jobs
  query: {
    type: Object as PropType<Parameters<typeof listJobsReactive>[0]>,
    default: () => ({
      status: 'PUBLISHED',
      page: 1,
      page_size: 10,
      order_by: '-posting_date'
    })
  },
  text: {
    type: String,
    default: ''
  },
  hideTitleWhenEmpty: {
    type: Boolean,
    default: true
  },
  seeMore: {
    type: Boolean,
    default: false
  },
  seeMoreBottom: {
    type: Boolean,
    default: false
  },
  pagination: {
    type: Boolean,
    default: false
  },
  headingTo: {
    type: String as PropType<string>,
    default: ''
  },
  headingToTarget: {
    type: String as PropType<string>,
    default: ''
  },
  lazy: {
    type: String as PropType<'CLIENT_ONLY' | 'TRUE' | 'FALSE'>,
    default: 'CLIENT_ONLY'
  },
  excludeJobIdSlug: {
    type: String as PropType<string | number>,
    default: ''
  }
})

const lazyLoading = computed(() => {
  if (props.lazy === 'CLIENT_ONLY') {
    return process.client
  }
  if (props.lazy === 'TRUE') {
    return true
  }
  if (props.lazy === 'FALSE') {
    return false
  }
  return process.client
})

const scrollTarget = ref<HTMLElement>()

const jobResult = await listJobsReactive(props.query, { lazy: lazyLoading.value })

if (!jobResult) {
  throw createError({
    statusCode: 500,
    message: 'Failed to fetch job data'
  })
}

const { data: jobData, pending: jobDataLoading } = jobResult
const jobs = computed(() => {
  if (!jobData.value) {
    return []
  }
  const jobs = jobData.value.data
    .map((job) => NAPIToTJob(t, job))
    .filter((job) => {
      return job.idSlug !== props.excludeJobIdSlug
    })

  return jobs
})

const emit = defineEmits(['update:loading', 'update:page'])
watch(
  () => jobDataLoading.value,
  (value) => {
    emit('update:loading', value)
  }
)

function handlePageChange(page: number) {
  emit('update:page', page)
}
</script>
<template>
  <div ref="scrollTarget" class="flex flex-col gap-[1.88rem]">
    <slot
      v-if="!hideTitleWhenEmpty || jobs.length > 0"
      name="heading"
      :page-count="jobs.length"
      :total-count="jobData?.count || 0"
    >
      <div v-if="props.text" class="flex flex-row items-center justify-between">
        <div v-if="props.text" class="text-h4 font-medium">
          {{ props.text }}
        </div>
        <ButtonBlackButton
          v-if="seeMore"
          :text="$t('common.list.seeMore')"
          variant="text"
          size="small"
          tabindex="-1"
          :mode="headingToTarget ? 'windowOpen' : 'navigation'"
          :to="toLocalePath(props.headingTo)"
        >
          <template #left-icon>
            <IconsFigmaArrowForwardOutlined />
          </template>
        </ButtonBlackButton>
      </div>
    </slot>

    <div v-if="jobDataLoading && jobs.length === 0" class="flex flex-col gap-[1.25rem]">
      <div v-for="n in 5" :key="n">
        <SkeletonLoader variant="Text" />
      </div>
    </div>
    <div v-else class="relative flex flex-col gap-[1.25rem]">
      <div
        v-if="jobDataLoading"
        class="absolute left-0 top-0 z-20 flex h-full w-full animate-pulse justify-center rounded-card bg-gray-30 opacity-70 ease-in"
      >
        <div class="mt-[2.88rem] h-[2rem] w-[2rem]"><IconsOthersLoading /></div>
      </div>
      <JobCard v-for="state in jobs" :key="state.idSlug" :job="state">
        <template v-if="state.logo && state.logo.url && state.company" #company-logo>
          <JobCardLogo :logo="state.logo" :company="state.company" :clickable="false" />
        </template>
        <template v-if="isPrimaryCandidate(user)" #save-button>
          <JobButtonSave
            :job="state"
            :variant="state.saved ? 'normal' : 'outlined'"
            size="small"
            ui-location="MainJobListCard"
          />
        </template>
      </JobCard>
    </div>
    <div v-if="props.seeMoreBottom && jobs.length > 0" class="mx-auto w-fit">
      <ButtonBlackButton
        :text="$t('common.list.seeMore')"
        size="small"
        variant="outlined"
        tabindex="-1"
        :to="toLocalePath(headingTo)"
        :mode="headingToTarget ? 'windowOpen' : 'navigation'"
      />
    </div>
    <div v-if="props.pagination && jobs.length > 0">
      <Paginator
        v-if="jobData && jobData.num_pages"
        :model-value="props.query.page"
        :total="jobData.num_pages"
        :scroll-target="scrollTarget"
        @update:model-value="handlePageChange"
      />
    </div>
  </div>
</template>
